import 'whatwg-fetch';

let token = null
export function setApiToken(apiToken) {
  token = apiToken;
}
const IS_DEV = process.env.NODE_ENV ==='development'

export function getBublBaseUrl() {
  //${window.APP_CONFIG.bublApiBaseUrl}
  return `https://${window.APP_CONFIG.bublDomain}`
}
export function apiClient(endpoint, { isBublManager, body, method, headers:additionalHeaders,  ...customConfig } = {}) {
  const headers = { 'content-type': 'application/json' };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const config = {
    ...(isBublManager?{}:{credentials: IS_DEV ? 'include': 'same-origin'}),
    method: method,
    ...customConfig,
    headers: {
      ...headers,
      ...additionalHeaders
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }
  let urlPrefix = isBublManager ? window.APP_CONFIG.apiBaseUrl :  getBublBaseUrl()
  return window
    .fetch(`${urlPrefix}/${endpoint}`, config)
    .then(async (response) => {
     let data ;
     try {
        data = await response.json();
     } catch(e) {
       //
     }
      if (response.ok) {
        return { data, response };
      } else {
        return Promise.reject({ response: response, data: data });
      }
    });
}
