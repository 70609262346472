import React, { useState, lazy, Suspense } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PrivateRoute } from "./components/shared/PrivateRoute";
import { StoreProvider } from "./stores/storeContexts";
import { useStores } from "./hooks/useStore";
import "react-toastify/dist/ReactToastify.css";
import { AppLogin } from "./components/Auth/AppLogin";
import { NewPasswordFormWrapper } from "./components/Auth/NewPasswordWrapper";

const HomeWrapper = lazy(() => import("./components/Home"));
const EmailVerifyLazy = lazy(() => import("./components/Auth/EmailVerify"));
const ResetPasswordFormWrapperLazy = lazy(() =>
  import("./components/Auth/ResetPassword")
);
const EmailConfirmLazy = lazy(() => import("./components/Auth/EmailConfirm"));
const DashboardLazy = lazy(() => import("./components/Dashboard/Dashboard"));

function CheckPreLogin(props) {
  let { auth } = useStores();
  auth
    .preLogin()
    .then(() => {
      props.setInitialLoader(false);
    })
    .catch((err) => {
      props.setInitialLoader(false);
    });
  return (
    <div
      data-testid="pre-loader"
      className="w-full h-full bg-surface text-on-surface flex flex-col justify-center items-center"
    >
      <div className="flex flex-col items-center justify-center">
        <FontAwesomeIcon data-testid="message-icon" icon={faSpinner} spin />
      </div>
    </div>
  );
}

function App() {
  let [initialLoader, setInitialLoader] = useState(true);
  return (
    <main className="App">
      <StoreProvider>
        {initialLoader ? (
          <CheckPreLogin setInitialLoader={setInitialLoader} />
        ) : (
          <Router basename={process.env.PUBLIC_URL}>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route exact path="/" component={HomeWrapper} />
                <Route path="/userconfirm" component={EmailVerifyLazy} />
                <Route
                  path="/changeemailconfirm"
                  component={EmailConfirmLazy}
                />
                <Route
                  path="/reset-password"
                  component={ResetPasswordFormWrapperLazy}
                />
                <Route
                  path="/auth-complete/:appId"
                  component={(props) => {
                    let appId = props.match.params.appId;
                    if (window.opener && window.opener.postAppActivation) {
                      window.opener.postAppActivation(appId);
                      window.opener.focus();
                      setTimeout(() => {
                        window.close();
                      }, 1000);
                    } else {
                      window.location = "/dashboard";
                    }

                    return <div>redirecting...</div>;
                  }}
                />
                <Route
                  path="/auth/new-password/:appId"
                  component={NewPasswordFormWrapper}
                />
                <Route path="/auth/signin/:appId" component={AppLogin} />
                <Route path="/auth/:appId" component={AppLogin} />
                <PrivateRoute path="/dashboard" component={DashboardLazy} />
              </Switch>
            </Suspense>
          </Router>
        )}
        <ToastContainer />
      </StoreProvider>
    </main>
  );
}

export default App;
