import React from "react";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { string, object } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { apiClient } from "../../api";
import { withTranslation } from "react-i18next";

class LoginForm extends React.Component {
  getErrorMessage(status) {
    if (status === "EMAIL_PASSWORD_ERROR")
      return `Login failed: please check your email${
        this.props.email ? ` and password` : ``
      }`;

    if (status === "SERVER_ERROR") {
      return "Oops something went wrong, please try again in few mins!";
    }
  }
  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isValid,
      dirty,
      status,
      isSubmitting,
      t,
    } = this.props;
 

    return (
      <div id="auth-full-container">
        <div id="auth-header" >
          {this.props.authApp.appInfo.logo?<img id="auth-app-logo" alt="app-logo" className="w-8 h-8" src={`${this.props.authApp.appInfo.logo?.indexOf('data:image')===-1?'data:image/jpeg;base64,':''}${this.props.authApp.appInfo.logo}`} />:null}
          <h2 id="auth-header-heading">{this.props.authApp.appInfo.name}</h2>
        </div>
        <form
          id="auth-app-form"
          onSubmit={handleSubmit}
        >
          <div className="auth-form-row">
            <label
              
              className="auth-app-input-label"
              htmlFor="email"
            >
              {t('Email')}
            </label>
            <input
              className="auth-app-input-field"
              id="email"
              name="email"
              type="text"
              placeholder={t('Email')}
              onChange={handleChange}
              onBlur={(e)=> {
                handleBlur(e)
              }}
              value={values.email}
              data-testid="email-input"
              />
            <p
              data-testid="email-error"
              className="auth-app-input-error"
            >
              {errors.email && touched.email ? t(errors.email) : ""}
            </p>
          </div>
          {this.props.email || this.props.auth.isUnderBubl ? (
            <div className="auth-form-row">
              <label
                className="auth-app-input-label"
                htmlFor="password"
              >
                {t('Password')}
              </label>
              <input
                className="auth-app-input-field"
                id="password"
                type="password"
                placeholder= {t('Password')}
                name="password"
                onChange={handleChange}
                onBlur={(e)=> {
                  handleBlur(e)
                }}
                value={values.password}
                data-testid="password-input"
              />
              <p
                data-testid="password-error"
                className="auth-app-input-error"
              >
                {errors.password && touched.password ? t(errors.password) : ""}
              </p>
            </div>
          ) : null}
          <p
            data-testid="form-error"
            id="auth-form-error"
          >
            {t(this.getErrorMessage(status))}
          </p>
          <div id="auth-submit-wrapper">
            <button
              id="auth-app-submit"
              data-testid="login-button"
              aria-label="Submit Login"
              disabled={!(isSubmitting || (dirty && isValid))}
              className={`${
                !(isValid && dirty) ? "auth-app-submit-disabled" : ""
              } `}
              type="submit"
            >
              {t("Login")}
              {isSubmitting ? <FontAwesomeIcon id="auth-app-btn-spinner" icon={faSpinner} spin /> : null}
            </button>
          </div>
          <p id="auth-app-powered-by" >{t('Powered By')}  <img src="/logo-bubl.png" alt="logo" width={120} /></p>
        </form>
      </div>
    );
  }
}

const schema = object().shape({
  email: string()
    .required("Email is required")
    .email("Valid Email is required"),
  password: string().required("Password is required"),
});

const LoginFormWithTs = withTranslation()(LoginForm)
export const LoginViewFormWrapper = withRouter(
  withFormik({
    mapPropsToValues: (props) => {
      return { email: props.email ?? "", password: "" };
    },
    validationSchema: (props) => {
      return schema;
    },
    handleSubmit: (
      values,
      { setSubmitting, props, setFieldError, setStatus, resetForm }
    ) => {
      let promise;
      console.log(props.authApp)
      promise = apiClient(`co-nect/v1/login`, {
        method: "POST",
        body: {
          email: values.email,
          password: values.password,
          serviceId: props.authApp.currentAppId
        },
      })
      .then((res) => {
          console.log(res,props, props.auth, props.authApp)
          // props.auth.setUser({token: data.accessToken})
          return props.auth.preLogin(props.skipPreCheck || false);
        })
        .then(() => {
          console.log("In close view")
          props.authApp.closeView()
        });

      promise.catch(({ data, response }) => {
        if (
          response &&
          (response.status === 404 ||
            response.status === 401 ||
            response.status === 400)
        )
          setStatus("EMAIL_PASSWORD_ERROR");
        else setStatus("SERVER_ERROR");
        setSubmitting(false);
      });
    },

    displayName: "LoginForm",
  })(LoginFormWithTs)
);
