import React from "react";
import { useTranslation } from "react-i18next";

import { observer } from "mobx-react";

export const LanguageSwitcher = observer(({ isOnNavbar, isStatic , isTransparent}) => {
  let { i18n } = useTranslation();
  const secondaryClass = isOnNavbar ? 'text-on-secondary':"text-secondary"
  const Switcher = (
    <div className={isOnNavbar?'ml-4':''}>
      <button
        className={`${
          i18n.resolvedLanguage === "en" ? "text-primary" : secondaryClass
        }  px-1 uppercase text-sm outline-none focus:outline-none`}
        type="button"
        onClick={() => {
          i18n.changeLanguage("en-US");
        }}
      >
        EN
      </button>
      <span className="text-on-surface text-sm">|</span>
      <button
        className={`${
          i18n.resolvedLanguage === "nl" ? "text-primary" : secondaryClass
        } px-1 uppercase text-sm outline-none focus:outline-none`}
        type="button"
        onClick={() => {
          i18n.changeLanguage("nl-NL");
        }}
      >
        NL
      </button>
    </div>
  );
        
  if (isStatic)
    return <div className={`fixed top-0 right-0 ${isTransparent? '': 'bg-background'}`}>{Switcher}</div>;

  return Switcher;
});
