import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { styleInject } from "../../common/helper";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useStores } from "../../hooks/useStore";
import { LanguageSwitcher } from "../shared/LanguageSwitcher";
import { LoginViewFormWrapper } from "./LoginView";

export const AppLogin = observer((props) => {
  const params = useParams();
  const {t} = useTranslation()
  const queryParams = useQueryParams();
  const { auth, authApp } = useStores();
  useEffect(() => {
    console.log(params.appId,queryParams.get('redirect') );
    authApp.setCurrentApp(params.appId, decodeURI(queryParams.get('redirect') ?? ""), decodeURI(queryParams.get('redirectPath') ?? ""));
  // eslint-disable-next-line
  }, []);
  const themeCss = authApp?.appInfo?.loginThemeCss
  useLayoutEffect(()=>{
    styleInject(themeCss, {id:'appTheme'})
  },[themeCss])
  return (
    <div
      data-testid="pre-loader"
      id="auth-page-container"
    >
      <LanguageSwitcher isStatic={true} isTransparent={true} />
      <div id="auth-laoder-wrapper">
        {authApp?.status === "loading" ? (
          <FontAwesomeIcon id="loader-spinner" data-testid="message-icon" icon={faSpinner} spin />
        ) : null}
        {authApp?.status === "loaded" ? (
          <LoginViewFormWrapper
            auth={auth}
            email={decodeURIComponent(queryParams?.get("email") ?? "")}
            authApp={authApp}
            skipPreCheck={true}
          />
        ) : null}
        {["redirection_failed","failedToLoadApp"].includes(authApp?.status) ? (
          <p id="error-text">ERR: {t(authApp.error)}</p>
        ) : null}
      </div>
    </div>
  );
});
